<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({
  sizeX: {
    type: String,
    default: "50px",
  },
  sizeY: {
    type: String,
    default: "50px",
  },
  size: {
    type: String,
    default: "",
  },
  radius: {
    type: String,
    default: "",
  },
  delay: {
    type: Number,
    default: 0,
  },
});

const width = ref("50px");
const height = ref("50px");

function setSize() {
  const { size, sizeX, sizeY } = props;

  if (size) {
    width.value = size;
    height.value = size;
  } else if (sizeX && sizeY) {
    width.value = sizeX;
    height.value = sizeY;
  }
}

const animationDelay = computed(() => {
  return `${props.delay * 0.01}s`;
});

onMounted(() => {
  setSize();
});
</script>

<template>
  <span
    data-anima="bottom"
    class="skeleton"
    :style="{
      width: width,
      height: height,
      borderRadius: radius,
      animationDelay: animationDelay,
    }"
  >
  </span>
</template>

<style scoped lang="scss">
.skeleton {
  display: block;
  background: var(--gray-10);
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
</style>
