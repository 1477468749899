<script setup>
import { inject, ref } from 'vue';
const props = defineProps({
  selected: {
    type: Object,
    Required: true
  },
})
const { selected } = props

const emitSearch = inject('emitSearch')
const setCancel = inject('setSearchOrStatus')
</script>

<template>
  <div @mouseleave="setCancel(false, {})" class="grid-itens" @click.stop >
    <div class="list-status" v-for="(item, index) in selected.statusList" :key="index">
      <span @click.stop="emitSearch(selected, item)" class="pointer">{{ item.title }}</span>
      <img @click.stop="emitSearch(selected, item)" class="pointer" :src="item.icon" :alt="item.title">
    </div>
  </div>
</template>
<style lang="scss" scoped>
.list-status {
  display: flex;
  justify-content: space-between;

  span {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    caret-color: transparent;
  }
  img{
    max-width: 19px;
    max-height: 19px;
    caret-color: transparent;
  }
}

.pointer {
  cursor: pointer;
}

.grid-itens {
  display: flex;
  flex-flow: column;
  -ms-flex-pack: justify;
  gap: 32px;
  justify-content: space-between;
  height: 100%;
  padding: 34px 28px 34px 28px;
}
</style>