<script setup>
import ButtonFilters from './ButtonFilter.vue';
import status from "@/assets/img/icons/opportunities/status.svg";
import { ref, computed } from "vue";
import { useStore } from "@/utils/useStore";

import { coneGray, wonGray, statusGray, pendingGray, lostGray} from "./svg"

import {
  GrDropDown,
  GrDropDownTrigger,
  GrDropDownContent,
  GrDropdownMenuGroup,
  GrDropDownItem,
} from "@/components/GrComponents";

const store = useStore();

const options = [
  {
    name: "Não atuado",
    status: "NOT_APPLICABLE",
    icon: require("@/assets/img/icons/opportunities/filter/cone-gray.svg"),
    svg: coneGray
  },
  {
    name: "Vencemos",
    status: "WON",
    icon: require("@/assets/img/icons/opportunities/filter/won-gray.svg"),
    svg: wonGray
  },
  {
    name: "Em andamento",
    status: "IN_PROGRESS",
    icon: require("@/assets/img/icons/opportunities/filter/status-gray.svg"),
    svg: statusGray
  },
  {
    name: "Pendente",
    status: "PENDING",
    icon: require("@/assets/img/icons/opportunities/filter/pending-gray.svg"),
    svg: pendingGray
  },
  {
    name: "Perdemos",
    status: "LOST",
    icon: require("@/assets/img/icons/opportunities/filter/lost-gray.svg"),
    svg: lostGray
  }
];

const opportunityStatus = computed(() => store.getters['opportunities/getFilterOpportunityStatus']);

const strokeIcons = ['NOT_APPLICABLE', 'IN_PROGRESS'];

const setFilter = (option) => {
  if (option.status === opportunityStatus.value) {
    store.dispatch('opportunities/setOpportunityStatus', null);
    return
  }
  store.dispatch('opportunities/setOpportunityStatus', option.status);
}

const selectedOption = computed({
  get() {
    return options.find((status => status.status == opportunityStatus.value))
  },
  set(value) {
    setFilter(value)
  }
});

const isStrokeIcon = (opportunityStatus) => {
  return strokeIcons.find((item) => opportunityStatus === item);
}

</script>
<template>
  <GrDropDown>
    <GrDropDownTrigger>
      <ButtonFilters :img="status" title="Status" />
    </GrDropDownTrigger>
    <GrDropDownContent size="md" class="drop-content">
      <GrDropdownMenuGroup class="drop-menu">
        <GrDropDownItem @click="setFilter(option)" v-for="(option, index) in options" :key="index" class="drop-item">

          <span :class="{ 'active': selectedOption?.name === option.name }">
            {{ option.name }}
          </span>

          <div 
            :class="{
                'svg-on-html-active-fill': selectedOption?.name === option.name && !isStrokeIcon(option.status),
                'svg-on-html-active-stroke': selectedOption?.name === option.name && isStrokeIcon(option.status),
                'svg-on-html-stroke': isStrokeIcon(option.status),
                'svg-on-html-fill': !isStrokeIcon(option.status)
            }" 
            v-html="option.svg"
        ></div>
        </GrDropDownItem>
      </GrDropdownMenuGroup>
    </GrDropDownContent> 
  </GrDropDown>
</template>
<style lang="scss" scoped>
.drop-content {
  padding: 33.62px 28.44px !important;
  display: grid;
}

.drop-menu {
  gap: 32px;
}

.drop-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;

  &:hover {
    background: transparent;

    span {
      color: var(--primary-800) !important;
      font-weight: var(--weight-semibold);
    }
  }
}

span {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  caret-color: transparent !important;
  transition: .5s;
  color: var(--gray-200);
}

.active {
  color: var(--primary-800) !important;
  font-weight: var(--weight-semibold);
}
</style>
<style leng="scss">
.drop-item {
  &:hover {
    .svg-on-html-fill {
      path {
        transition: 0.5s;
        fill: var(--primary-800);
      }
    }

    .svg-on-html-stroke {
      path {
        transition: 0.5s;
        stroke: var(--primary-800);
      }
    }
  }
}

.svg-on-html-active-fill {
  path {
    transition: 0.5s;
    fill: var(--primary-800);
  }
}

.svg-on-html-active-stroke {
  path {
    transition: 0.5s;
    stroke: var(--primary-800);
  }
}
</style>