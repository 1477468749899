<script setup>
import { ExportDescription, ExportTitle } from "../base-components";
import Multiselect from "vue-multiselect";
import { ref, watch } from "vue";

const emit = defineEmits(["update:relations"]);

const optionSelected = ref();
const options = [
  {
    name: "Cliente",
    value: "lead",
  },
  {
    name: "Produto",
    value: "product",
  },
];

watch(optionSelected, (newValue) => {
  const values = newValue.map((item) => item.value);
  emit("update:relations", values);
});
</script>

<template>
  <div class="content-columns">
    <ExportTitle>Quais os tipos de dados que deseja exportar?</ExportTitle>

    <ExportDescription>
      Selecione os tipos abaixo para que possamos organizar a exportação para você
    </ExportDescription>

    <multiselect
      v-model="optionSelected"
      :multiple="true"
      label="name"
      track-by="value"
      placeholder="Selecionar"
      :options="options"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
    >
    </multiselect>
  </div>
</template>

<style lang="scss" scoped>
.content-columns {
  h1,
  p {
    padding: 0 50px;
  }
}

.input-email {
  height: var(--h-12);
  padding: var(--spacing-2-5);

  cursor: pointer;
  outline: none;

  border-bottom: 1px solid var(--gray-50);
  white-space: nowrap;
  overflow: hidden;

  &:focus-visible {
    outline: none !important;
  }

  font-size: 28px;
  font-weight: var(--weight-extrabold);
  text-align: center;
}
</style>
