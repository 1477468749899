<script setup>
import { computed, ref } from "vue";
import ButtonFilter from "./ButtonFilter.vue";
import search from "@/assets/img/icons/opportunities/search.svg";
import { GrFilterTrigger } from "@/components/GrComponents/filter"
import { useStore } from "@/utils/useStore"
const store = useStore()

const filters = ref([
  {
    type: "search",
    title: "Buscar pelo ID",
    icon: require("@/assets/img/icons/opportunities/filter/hashtag.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/hashtag-black.svg'),
    placeholder: "Buscar Id",
    getter: computed(() => store.getters["opportunities/getFilterId"]),
    mutationName: 'setFilterId'
  },
  {
    type: "search",
    title: "E-mail do cliente",
    icon: require("@/assets/img/icons/opportunities/filter/email.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/email-black.svg'),
    placeholder: "Buscar email",
    getter: computed(() => store.getters["opportunities/getFilterLeadEmail"]),
    mutationName: 'setFilterLeadEmail'
  },
  {
    type: "search",
    title: "Nome do cliente",
    icon: require("@/assets/img/icons/opportunities/filter/profile.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/profile-black.svg'),
    placeholder: "Buscar nome",
    getter: computed(() => store.getters["opportunities/getFilterLeadName"]),
    mutationName: 'setFilterLeadName'
  },
  {
    type: "search",
    title: "Telefone do cliente",
    icon: require("@/assets/img/icons/opportunities/filter/phone-call.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/phone-call-black.svg'),
    placeholder: "Buscar telefone",
    getter: computed(() => store.getters["opportunities/getFilterleadPhone"]),
    mutationName: 'setFilterleadPhone'
  }
]);


async function setSearchInFilter(search, item) {
  await store.dispatch('opportunities/setNewValueInFilter', { mutationName: item.mutationName, newValue: search })
  await store.dispatch('opportunities/setPageOne')
  store.dispatch('opportunities/getOpportunities')
}

</script>
<template>
  <GrFilterTrigger @search="setSearchInFilter" :filters="filters">
    <ButtonFilter :img="search" title="Buscar" />
  </GrFilterTrigger>
</template>
<style lang="scss" scoped>

</style>