<script setup>
import { computed } from "vue";
import { useStore } from "@/utils/useStore";

import { GrPagination } from "@/components/GrComponents/pagination";

const store = useStore();

const pagination = computed(() => {
  return store.getters["opportunities/getPagination"];
});

async function changeItemsPerPage(perPage) {
  await store.dispatch("opportunities/updateItemsPerPage", { perPage, page: 1 });
}

async function changeCurrentPage(page) {
  await store.dispatch("opportunities/updateCurrentPag", { perPage: pagination.value.perPage, page });
}
</script>

<template>
  <GrPagination
    class="opportunities-pagination"
    :default-page="pagination.currentPage"
    :items-per-page="pagination.perPage"
    :items-per-page-visible="pagination.perPageVisible"
    :total="pagination.total"
    :itemsPerPageVisible="false"
    @updateItemsPerPage="changeItemsPerPage"
    @updateCurrentPage="changeCurrentPage"
  />
</template>

<style scoped lang="scss">
.opportunities-pagination {
  margin-top: 48px;
}
</style>