import { render, staticRenderFns } from "./ExportDescription.vue?vue&type=template&id=52176235&scoped=true"
var script = {}
import style0 from "./ExportDescription.vue?vue&type=style&index=0&id=52176235&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52176235",
  null
  
)

export default component.exports