<script setup>
import { HeaderOpportunities, ListOpportunities, FilterListOpportunities } from '../components/Views/Opportunities';
import { onMounted, ref } from "vue";
import { useStore } from "@/utils/useStore";
import { useRouter } from 'vue-router/composables';

const router = useRouter();
const store = useStore();
const flagInclude = ref(false)

onMounted(async () => {
  flagInclude.value = await store.dispatch("getUniqueFlag", "sales-recovery")
  if (!flagInclude.value){
    router.push('/dynamicRoute/home');
  }
});
</script>
<template>
  <div class="opportunities" v-if="flagInclude">
    <HeaderOpportunities />
    <FilterListOpportunities/> 
    <ListOpportunities />
  </div>
</template>
<style lang="scss" scoped>

</style>
<style lang="scss">
.opportunities{
  *{
    font-family: "Plus Jakarta Sans", sans-serif;
  }
}
</style>