<script setup>
import FilterSearch from "@/components/Views/Opportunities/filters/buttons/FilterSearch.vue";
import FilterExport from "./export";
import FilterStatus from "@/components/Views/Opportunities/filters/buttons/FilterStatus.vue"
</script>

<template>
  <section>
    <FilterStatus id="profit_btn_filtroporstatus" />
    <FilterSearch id="profit_btn_buscar" />
    <FilterExport id="profit_btn_exportar" />
  </section>
</template>

<style scoped lang="scss">
section {
  display: flex;
  align-items: center;
  gap: 30px;
}
</style>
