<script setup>
import { inject, provide, } from 'vue';

const props = defineProps({
  filters: {
    type: Array,
    Required: true
  }
})

const setSearch = inject('setSearch')

</script>

<template>
  <div class="grid-itens">
    <div class="list-filter" v-for="(item, index) in filters" :key="index">
      <span @click.stop="setSearch(item)" class="pointer">{{ item.title }}</span>
      <img @click.stop="setSearch(item)" class="pointer" :src="item.icon" :alt="item.title">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-filter {
  display: flex;
  justify-content: space-between;

  span {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    caret-color: transparent !important;
  }

  img {
    max-width: 19px;
    max-height: 19px;
    caret-color: transparent !important;
  }
}

.pointer {
  cursor: pointer;
}

.grid-itens {
  display: flex;
  flex-flow: column;
  -ms-flex-pack: justify;
  gap: 32px;
  justify-content: space-between;
  height: 100%;
  padding: 34px 28px 34px 28px;
}
</style>