<script setup>
import {
  GrTable,
  GrTableHeader,
  GrTableHead,
  GrTableBody,
  GrTableRow,
  GrTableCell,
  GrTableEmpty
} from "@/components/GrComponents/table";
import { GrTag } from "@/components/GrComponents/tag";
import { GrAvatar } from "@/components/GrComponents/avatar";

import { onMounted, computed, getCurrentInstance, ref } from "vue";
import { useStore } from "@/utils/useStore";
import PaginateOpportunities from "./../paginate/PaginateOppotunities.vue"
import { money } from "@/utils/money";
import moment from "moment";
import { useRouter } from "vue-router/composables";

const store = useStore();
const router = useRouter();
const loading = ref(false);

const opportunities = computed(() => {
  return store.getters["opportunities/getOpportunities"];
});

function classCreated(data){
  return `Criado ${moment(data).locale("pt").fromNow()}`
}

function classUpdated(data){
  return moment(data).format("DD/MM/YYYY [às] HH:mm")
}

function getStatusVariant(status) {
  const variants = {
    WON: "success",
    IN_PROGRESS: "info",
    LOST: "danger",
    NOT_APPLICABLE: "warning-light",
    PENDING: "danger",
  };
  return variants[status] || "warning-light";
}

function getStatusText(status) {
  const texts = {
    WON: "Convertida",
    IN_PROGRESS: "Em andamento",
    LOST: "Não convertida",
    NOT_APPLICABLE: "Não atuado",
    PENDING: "Pendente",
  };
  return texts[status] || status;
}

async function getOpportunities() {
  loading.value = true;

  try {
    await store.dispatch("opportunities/getOpportunities");  
  } catch (error) {
    console.error(error);
  } finally{
    loading.value = false
  }
}

async function openOpportunity(id) {
  router.push({ name: "Opportunity", params: { id } })
}

onMounted(async () => {
 await getOpportunities();
});
</script>

<template>
  <div >
    <GrTable :loading="loading">
      <GrTableHeader>
        <GrTableHead> Data de criação </GrTableHead>
        <GrTableHead> Nome do cliente </GrTableHead>
        <GrTableHead> Produto </GrTableHead>
        <GrTableHead> Valor Profit </GrTableHead>
        <GrTableHead> Atualizado há </GrTableHead>
        <GrTableHead> Vendedor </GrTableHead>
        <GrTableHead> Status </GrTableHead>
      </GrTableHeader>

      <GrTableBody >
        <GrTableRow class="row-opportunity" v-for="(item, index) in opportunities" :key="index" @click="openOpportunity(item?.id)">
          <div class="table-hover-create" :data-create="classCreated(item?.created_at)">
            <GrTableCell>
              {{ moment(item?.created_at).format("DD/MM/YYYY [às] HH:mm") }}
            </GrTableCell>
          </div>
          <GrTableCell>{{ item?.lead_name }}</GrTableCell>
          <GrTableCell>{{ item?.name }}</GrTableCell>
          <GrTableCell> {{ money(item?.amount, "R$") }}</GrTableCell>
          <div class="table-hover-update" :data-create="classUpdated(item?.updated_at)">
            <GrTableCell>{{ moment(item?.updated_at).locale("pt").fromNow() }}</GrTableCell>
          </div>

          <GrTableCell >
            <div class="cell-seller">
              <GrAvatar v-if="item?.user_first_name" size="xs" :src="item?.user_photo" :placeholder="item?.user_first_name" />
              {{
                item?.user_first_name && item?.user_last_name
                ? `${item.user_first_name} ${item.user_last_name}`
                : "Sem vendedor"
              }}
            </div>

          </GrTableCell>

          <GrTableCell class="cell-status">
            <GrTag class="tag" :variant="getStatusVariant(item?.status)">
              {{ getStatusText(item?.status) }}
            </GrTag>

            <img
              src="@/assets/img/icons/opportunities/arrow.svg"
              alt="arrow"
            />
          </GrTableCell>
        </GrTableRow>
      </GrTableBody>

      <GrTableEmpty v-if="!opportunities.length"> Sem resultados! </GrTableEmpty>
    </GrTable>
    <PaginateOpportunities v-if="!loading && opportunities.length > 0" />
  </div>
</template>

<style lang="scss" scoped>
.row-opportunity {
  cursor: pointer;
}

.cell-status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: var(--spacing-1-5);

  img {
    padding: 5px;
    cursor: pointer;
  }
}

.cell-seller{
  display: flex;
  align-items: center;
  gap: 10px;
}

.origin {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
@mixin date($left, $bottom) {
  &:hover{
    position: relative;
    &::after{
      content: attr(data-create);
      opacity: 0.8;
      color: #ffffff;
      font-family: "Plus Jakarta Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: absolute;
      padding: 10px 14px;
      border-radius: 5px;
      background: #001E1B;
      bottom: $bottom;
      left: $left;
      animation: opacity 0.3s ease-in-out;
    }
  }
  @keyframes opacity {
    0%{
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
}
.table-hover-create{
@include date($left: 50%, $bottom: 55%);
}
.table-hover-update{
@include date($left: -20px, $bottom: 60%);
}
</style>
