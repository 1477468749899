<script setup>
import { inject } from "vue";

const loading = inject("loading");
</script>

<template>
  <tbody v-if="!loading" class="container-empty">
    <section>
      <p>
        <slot />
      </p>
    </section>
  </tbody>
</template>

<style scoped lang="scss">
.container-empty {
  width: 100%;
  height: 100px;
  section {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;

    p {
      color: var(--gray-200);
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
