<script setup>
import { ExportDescription, ExportTitle } from "../base-components";
import { computed, ref, watch } from "vue";

const props = defineProps({
  email: String,
});

const emit = defineEmits(["update:email"]);

const email = ref(props.email);

const isValidEmail = computed(() => validateEmail(email.value));

function validateEmail(email) {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
}

watch(email, (newEmail) => {
  emit("update:email", isValidEmail.value ? newEmail : "");
});
</script>

<template>
  <div>
    <ExportTitle class="title">Em qual e-mail deseja receber o arquivo de exportação?</ExportTitle>

    <ExportDescription> Digite o e-mail no campo abaixo </ExportDescription>

    <input
      type="email"
      v-model="email"
      class="input-email-export"
      :class="{ invalid: !isValidEmail && email.length > 0, valid: isValidEmail }"
    />
  </div>
</template>

<style lang="scss" scoped>
.title {
  padding: 0 50px;
}

.valid {
  color: var(--primary-800) !important;
}

.invalid {
  color: var(--gray-100) !important;
}

.input-email-export {
  height: var(--h-12);
  width: 100%;
  padding: var(--spacing-2-5);

  transition: all 0.2wes !important;

  cursor: pointer;
  outline: none;

  font-size: 28px !important;
  font-weight: var(--weight-extrabold);
  text-align: center;

  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--gray-50) !important;

  &:focus-visible,
  &:hover {
    outline: none !important;
    border-color: var(--gray-100) !important;
  }
}
</style>
